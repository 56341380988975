import { H3, Button, Localize } from '@everlywell/leaves';
import { PageInfo } from 'components/eligibility-file/common';
import { Divider, useWizard } from 'components/ui';
import { useFindManyProducts } from 'hooks';
import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  COVID_19_BASE_SKU,
  COVID_19_SKU_EXCEPTION,
  COVID_19_ID,
  ERROR_CONTENT,
} from 'utils/constants';
import { analytics } from 'utils/helpers';

import * as S from '../styles';
import KitItemFields from './form-fields/KitItemFields';
import { ChildProps } from 'components/ui/Wizard';

const SelectTests = ({ navigation, isCurrentStep }: ChildProps) => {
  const { goToNextStep } = navigation;
  const { state, dispatch } = useWizard();

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      tests:
        state?.form?.selectTests.length >= 1 ? state?.form?.selectTests : false,
    },
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const contentfulProductIds = (kit: Kit) => {
    const isCovidProduct =
      COVID_19_BASE_SKU.test(kit?.ew_variant_sku) &&
      !COVID_19_SKU_EXCEPTION.test(kit?.ew_variant_sku);

    return {
      ...kit,
      contentfulProductId: isCovidProduct ? COVID_19_ID : kit?.ew_product_id,
    };
  };

  const kits = state?.member?.kits.map(contentfulProductIds) || [];
  const productIds = kits?.map((kit: Kit & { contentfulProductId: number; }) => kit.contentfulProductId);
  const hasAvailableKits = kits.length >= 1;

  const contentfulData = useFindManyProducts({ productIds });

  const firstName = state?.member?.first_name;

  const eventData = {
    page_name: 'select_tests_page',
  };

  const onSubmit = async (formData: OrderWizardState['form']) => {
    const { tests } = formData;
    const selectTests = Array.isArray(tests) ? tests : [tests];
    const kit = kits.filter((kit: Kit) =>
      selectTests.includes(String(kit.ew_product_id)),
    )[0];

    dispatch({
      type: 'updateData',
      data: {
        member: state?.member,
        form: {
          ...state?.form,
          selectTests,
        },
        contentfulData,
      },
    });

    analytics.track('CTA Clicked', {
      ...eventData,
      tests_amount: selectTests.length,
      tests_type: kit.kit_type,
      text: 'Selected Order Tests Successfully',
    });

    goToNextStep();
  };

  const parseContentfulData = (contentfulData: Product[], kit: Kit) => {
    const foundCD = contentfulData.find((cD) => cD.productId === kit.ew_product_id)

    return {
      imageSrc: foundCD?.boxImage?.gatsbyImageData?.images?.fallback?.src,
      displayName: foundCD?.displayName,
      shortDescription: foundCD?.shortDescription,
    }
  }

  useEffect(() => {
    if (!isCurrentStep) return;

    analytics.track('Page Viewed', {
      ...eventData,
      label: 'Viewed Select Tests Page',
    });
    // it should run only when is `isCurrentStep` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentStep]);

  return (
    <FormProvider {...methods}>
      <S.Form name="select-tests" onSubmit={handleSubmit(onSubmit)}>
        {isCurrentStep && (
          <PageInfo title="Select Tests" pageName="order - select tests" />
        )}
        <S.FormHeading>
          <H3 as="h1">
            Welcome, <Localize name="first-name">{firstName}</Localize>!
          </H3>
          <p>
            After you complete your test, we'll send you and your doctor a copy
            of your results if we have your doctor on file. Your results will be
            available online through our secure, HIPAA-compliant results
            platform.
          </p>
        </S.FormHeading>

        <Divider />

        {hasAvailableKits && (
          <>
            <S.LabelGroup>
              <S.UppercaseHeading>Available Tests</S.UppercaseHeading>
              <H3>Select all the tests you would like to receive</H3>
            </S.LabelGroup>

            <Divider spacing="24px" isHidden />

            <S.KitGroup as="fieldset">
              <legend hidden>Select all the tests you would like to receive</legend>
              {kits.map((kit: Kit) => (
                <KitItemFields
                  key={kit.ew_product_id}
                  productId={kit.ew_product_id}
                  contentfulData={parseContentfulData(contentfulData, kit)}
                />
              ))}
            </S.KitGroup>
          </>
        )}

        {!hasAvailableKits && (
          <S.Paragraph
            dangerouslySetInnerHTML={{
              __html: ERROR_CONTENT.UNAVAILABLE_TESTS,
            }}
          />
        )}

        <Divider isHidden />

        <S.ButtonGroup>
          <Button isDisabled={!isValid || !hasAvailableKits}>
            Order tests
          </Button>
        </S.ButtonGroup>
      </S.Form>
    </FormProvider>
  );
};

export default SelectTests;
