import { SEO } from 'components/common';
import OrderPage from 'components/eligibility-file/pages/order';
import { graphql } from 'gatsby';
import React from 'react';

export const Head = () => <SEO />;

const Order = ({ data }) => {
  const { customerLogo, partnerName, footerInfo } =
    data.contentfulOptInLandingPage;

  return (
    <OrderPage
      customerLogo={customerLogo?.gatsbyImageData?.images?.fallback?.src}
      partnerName={partnerName}
      footerInfo={footerInfo?.footerInfo}
    />
  );
};

export const query = graphql`
  query OrderPageQuery($brandId: String!) {
    contentfulOptInLandingPage(brandId: { eq: $brandId }) {
      partnerName
      customerLogo {
        gatsbyImageData
      }
      footerInfo {
        footerInfo
      }
    }
  }
`;

export default Order;
