import { ALL_US_STATES_NO_HEADING } from 'utils/constants';

const MEMBER_API_MAPPED_PROPS = {
  address1: 'address_1',
  address2: 'address_2',
  zipcode: 'zip_code',
  city: 'city',
  state: 'state',
  email: 'email',
  phoneNumber: 'phone_home',
} as const;

interface Member {
  address_1: string;
  address_2: string;
  city: string;
  date_of_birth: string;
  email: string;
  first_name: string;
  gender: string;
  last_name: string;
  member_facing_id: string | null;
  phone_home: string | null;
  physician: string | null;
  preferred_language: string | null;
  state: string;
  zip_code: string;
}

type FormDataProps = ['address1', 'address2', 'zipcode', 'city', 'state', 'email', 'phoneNumber'];
type ValueOf<T> = T[keyof T];
type MemberProps = keyof typeof MEMBER_API_MAPPED_PROPS;
type MemberValues = ValueOf<typeof MEMBER_API_MAPPED_PROPS>;

export const getMemberState = (selectedState: string | undefined) => {
  if (!selectedState) return null;

  const foundState = ALL_US_STATES_NO_HEADING.find(({ text, value }) => {
    const lowerCasedSelectedState = selectedState?.toLowerCase();

    return (
      text.toLocaleLowerCase() === lowerCasedSelectedState ||
      value.toLocaleLowerCase() === lowerCasedSelectedState
    );
  });

  return foundState?.value ?? null;
};

const getMemberPropValue = (member: Member, prop: MemberProps) => {
  const value = member[MEMBER_API_MAPPED_PROPS[prop]] as MemberValues;

  if (prop === 'state') return getMemberState(value);

  return value;
};

export const hasFormChanged = (formData: OrderWizardState['form']['shippingInformation'], member: Member) => {
  const formDataProps = Object
    .keys(formData)
    .filter(prop => !['firstName', 'lastName', 'shippingId'].includes(prop)) as FormDataProps;

  const isChanged = formDataProps.some((prop) => {
    const value = getMemberPropValue(member, prop);
    const formValue = formData[prop];

    if (prop === 'email' && formValue === '') return false;
    if (prop === 'phoneNumber' && formValue === '') return false;
    if (prop === 'state' && formValue === value) return false;

    return formValue !== value;
  });

  return isChanged;
};
