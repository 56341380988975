type ClientData = { programName: string; enterpriseClientId: number | null };

const getClientData = (selectedTests: string[], kits: Kit[]): ClientData => {
  const kit = kits?.find((kit: Kit) => kit.ew_product_id === Number(selectedTests[0]))

  return {
    programName: kit?.program_description ?? '',
    enterpriseClientId: kit?.enterprise_client_id ?? null,
  }
}

export { getClientData };
