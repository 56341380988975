import { colors, size, mediaQueries, Success, H2 } from '@everlywell/leaves';
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import styled from 'styled-components';

const successSvgString = encodeURIComponent(renderToStaticMarkup(<Success />));

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  gap: ${size.lg}px;
  flex-wrap: wrap;

  input + label {
    margin: 0;
  }

  input:checked + label {
    background-color: ${colors.green1};
    box-shadow: 0 0 0 1px ${colors.green4};
  }

  ${mediaQueries.forTabletVerticalDown} {
    > * {
      width: 100%;
    }
  }
`;

const FormControls = styled.div<{ numberOfColumns?: number; mobileNumberOfColumns?: number; }>`
  margin-bottom: ${size.lg}px;

  input:not([type='radio'], [type='checkbox']) + label {
    font-weight: 600;
    color: ${colors.gray4};
    margin-bottom: ${size.xs2}px;
  }

  ${RadioGroup} {
    margin-bottom: ${size.lg}px;
  }

  input[type='number'],
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
  }

  ${(props) =>
    props.numberOfColumns &&
    `
    display: grid;
    grid-template-columns: repeat(${props.numberOfColumns}, 1fr);
    gap: ${size.lg}px;

    ${mediaQueries.forTabletVerticalDown} {
      grid-template-columns: repeat(${props.mobileNumberOfColumns ?? 1}, 1fr);
      gap: ${props.mobileNumberOfColumns ? `${size.lg}px` : 0};
    }
  `}
`;

const InputLabel = styled.label`
  font-weight: 600;
  color: ${colors.gray4};
`;

const InputLabelContainer = styled.div`
  display: inline-flex;
  margin-bottom: ${size.xs2}px;
  gap: ${size.xs2}px;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > button:only-child {
    min-width: 270px;
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: ${colors.green4};
`;

const LabelGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;

  h3 {
    font-weight: 500;
  }
`;

const KitGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${size.lg}px;
  width: 100%;
`;

const KitOptionBase = styled.label<{ checked: boolean; }>`
  &:before,
  &:after {
    content: '';
    width: ${size.lg}px;
    height: ${size.lg}px;
  }

  &:before {
    display: inline-block;
    border: 1px solid;
    flex-shrink: 0;
    border-color: ${(props) =>
    props.checked ? colors.green4 : 'rgba(113, 172, 133, 0.5)'};
    box-shadow: 0px 2px 15px -5px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    transition: all 0.3s;
  }

  &:after {
    position: absolute;
    background-image: ${(props) =>
    props.checked ? `url("data:image/svg+xml,${successSvgString}")` : 'none'};
    background-size: ${size.md}px ${size.md}px;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid transparent;
  }

  strong {
    font-weight: 400;
  }

  &:hover {
    &:before {
      border-color: ${colors.green4};
    }
  }

  &:focus-within {
    outline-style: auto;
    outline-width: 1px;
    outline-color: Highlight;
    outline-color: -webkit-focus-ring-color;
  }

  span {
    color: ${colors.gray4};
    font-weight: 300;
  }

  /*
    NOTE: Using "opacity: 0" instead of "display: none" to
    preserve the focusability of parent elements.
  */
  input[type='checkbox'] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
`;

const KitOptionLabel = styled(KitOptionBase)`
  display: flex;
  position: relative;
  padding: ${size.lg}px;
  align-items: flex-start;
  gap: ${size.md}px;
  background-color: ${colors.white};
  border: 1px solid;
  border-radius: 0;
  border-color: ${(props) => (props.checked ? colors.green4 : colors.green1)};
  box-shadow: 0px 7px 30px -10px rgba(220, 228, 229, 0.5);

  &:before {
    border-color: ${(props) => (props.checked ? colors.green3 : colors.green2)};
  }

  &:before,
  &:after {
    width: 20px;
    height: 20px;
    box-sizing: border-box;
  }
`;

const Label = styled.div`
  align-items: start;
  gap: 16px;
  display: grid;
  grid-template-columns: 120px 1fr;
`;

const ImageDiv = styled.figure`
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: center;
`;

const Image = styled.img`
  max-width: 110px;
  height: auto;
`;

const LabelText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LabelTextTitle = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  color: ${colors.green4};
`;

const LabelTextSubTitle = styled.p`
  margin: 0;
  font-size: ${size.md}px;
  font-weight: 300;
  color: ${colors.gray4};
`;

const UppercaseHeading = styled(H2)`
  text-transform: uppercase;
  color: ${colors.green4};
  font-size: ${size.md}px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 2.25rem;
`;

const Form = styled.form`
  color: ${colors.gray4};
`;

const FormHeading = styled.div`
  p {
    font-weight: 300;
    font-size: 18px;
  }

  h3 {
    font-weight: 500;
  }
`;

const FormGroup = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
`;

const FormGroupTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  [role="heading"] {
    text-align: left;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: ${colors.gray4};
  }
`;

const FormGroupValue = styled.div<{ flexDirection?: string; }>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection ?? `row`};
  gap: 5px;
  padding-top: 4px;
  padding-bottom: 4px;

  > p {
    font-size 16px;
    font-weight: 600;
    color: ${colors.gray4}
  }

  div {
    font-weight: 400;
  }
`;

const ChangeButton = styled.a`
  color: ${colors.green4};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${colors.green5};
    text-decoration: underline;
  }
`;

const Paragraph = styled.p`
  font-weight: 300;
  font-size: 18px;
`;

export {
  FormControls,
  InputLabel,
  InputLabelContainer,
  ButtonGroup,
  RadioGroup,
  Link,
  LabelGroup,
  KitGroup,
  KitOptionLabel,
  Label,
  ImageDiv,
  Image,
  LabelText,
  LabelTextTitle,
  LabelTextSubTitle,
  UppercaseHeading,
  Form,
  FormHeading,
  FormGroup,
  FormGroupTitle,
  FormGroupValue,
  ChangeButton,
  Paragraph,
};
