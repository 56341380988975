import { Wizard, ToastContainer } from 'components/ui';
import useGetConfigValues from 'hooks/useGetConfigValues';
import React from 'react';

import { Layout } from '../../common';
import ReviewStep from './ReviewStep';
import SelectTests from './SelectTestsStep';
import ShippingInformationStep from './ShippingInformationStep';
import VerifyInformation from './VerifyInformationStep';

import { QueryResult } from 'hooks/useGetConfigValues';

interface Props {
  customerLogo: string;
  partnerName: string;
  footerInfo: string;
}

const castBooleanValue = (value: any) => {
  if (typeof value == 'string') {
    return value == 'true';
  }else{
    return value ?? false;
  }
}

const getInitialState = (data: QueryResult["data"]) => {
  const { enterprise_partner_configs: epc } = data ?? {};

  const { health_plan_config, third_party_tenant_name } = epc?.[0] ?? {};

  const {
    modal_text,
    modal_image,
    member_label,
    caregiver_label,
    unique_id_label,
    email_required,
    phone_required
  } = health_plan_config ?? {};

  const castedEmailRequired = castBooleanValue(email_required);
  const castedPhoneRequired = castBooleanValue(phone_required);

  return {
    form: {
      verifyInformation: {
        kind: 'member',
        id: '',
        dob: '',
        zipcode: '',
        lastName: '',
        consent: false,
        userAgreement: false,
      },
      selectTests: [],
      shippingInformation: {
        address_1: '',
        address_2: '',
        city: '',
        state: '',
        zipcode: '',
        phone_number: '',
        email: '',
      },
    },
    configValues: {
      caregiver_label: caregiver_label ?? '',
      member_label: member_label ?? '',
      tooltip_image: modal_image ?? '',
      tooltip_text: modal_text ?? '',
      member_id_label: unique_id_label ?? '',
      tenant_name: third_party_tenant_name ?? '',
      email_required: castedEmailRequired,
      phone_required: castedPhoneRequired,
    },
  };
};

const OrderPage = ({ customerLogo, partnerName, footerInfo }: Props) => {
  const { loading, data } = useGetConfigValues();

  if (loading) return;

  return (
    <Layout
      customerLogo={customerLogo}
      partnerName={partnerName}
      footerInfo={footerInfo}
    >
      <ToastContainer />
      <Wizard
        initialState={getInitialState(data!)}
        steps={['Verify', 'Tests', 'Shipping', 'Review']}
      >
        {/*
          "Wizard children have props injected by the parent, which results in
          a TypeScript error of "...missing the following properties".
          This error is not due to a typing mistake, but rather a limitation of static typing.
        */}
        {/* @ts-ignore */}
        <VerifyInformation />
        {/* @ts-ignore */}
        <SelectTests />
        {/* @ts-ignore */}
        <ShippingInformationStep />
        {/* @ts-ignore */}
        <ReviewStep />
      </Wizard>
    </Layout>
  );
};

export default OrderPage;
