import { H3 } from '@everlywell/leaves';
import { PageInfo } from 'components/eligibility-file/common';
import ShippingInfoForm from 'components/forms/ShippingInfoForm';
import { Divider, useWizard } from 'components/ui';
import React, { useEffect } from 'react';
import { analytics, normalizePhone } from 'utils/helpers';

import * as S from '../styles';
import { getMemberState, hasFormChanged } from './helpers';
import { ChildProps } from 'components/ui/Wizard';

const PAGE_NAME = 'order - shipping information';

const ShippingInformationStep = ({
  navigation,
  stepIndex,
  currentStep,
  isCurrentStep,
}: ChildProps) => {
  const { goToNextStep } = navigation;
  const { state, dispatch } = useWizard();

  const eventData = {
    page_name: PAGE_NAME,
  };

  const emailRequired: boolean = state.configValues.email_required;
  const phoneRequired: boolean = state.configValues.phone_required;

  const onSubmit = (formData: OrderWizardState['form']['shippingInformation']) => {
    dispatch({
      type: 'updateData',
      data: {
        form: {
          ...state?.form,
          shippingInformation: formData,
        },
      },
    });

    analytics.track('CTA Clicked', {
      ...eventData,
      edited_shipping_information: hasFormChanged(formData, state?.member),
      text: 'Saved Shipping Information Successfully',
    });

    goToNextStep();
  };

  // MixPanel tracking for email or phone field error
  const onEmailOrPhoneFieldError = (field: HTMLInputElement) => {
    const emailValidationFailed = (field.name == 'email' && emailRequired);
    const phoneValidationFailed = (field.name == 'phoneNumber' && phoneRequired);
    if (!emailValidationFailed && !phoneValidationFailed) {
      return;
    }
    const kit = state?.member?.kits?.[0];
    const attributeName = emailValidationFailed ? 'Email Address' : 'Phone Number';
    analytics.track(`Error: ${attributeName} is Missing or Invalid`, {
      text: `Please enter a valid ${attributeName}`,
      partnerId: process.env.GATSBY_ENTERPRISE_PARTNER_ID,
      programName: kit?.program_description,
      testTypes: state?.member?.kits.map((kit: any) => kit.kit_type)
    })
  }

  useEffect(() => {
    if (!isCurrentStep) return;

    analytics.track('Page Viewed', { ...eventData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentStep]);

  if (stepIndex !== currentStep) return null;

  return (
    <>
      {isCurrentStep && (
        <PageInfo title="Shipping Information" pageName={PAGE_NAME} />
      )}
      <S.FormHeading>
        <H3 as="h1">Shipping Information</H3>
        <p>
          Verify your shipping address below. This is the address we will send
          your results to once the lab processes your sample.
        </p>
      </S.FormHeading>

      <Divider isHidden spacing="16px" />

      <ShippingInfoForm
        initialValues={{
          firstName: state?.member?.first_name,
          lastName: state?.member?.last_name,
          address1:
            state?.form?.shippingInformation?.address1 ||
            state?.member?.address_1,
          address2:
            state?.form?.shippingInformation?.address2 ||
            state?.member?.address_2,
          city: state?.form?.shippingInformation?.city || state?.member?.city,
          zipcode:
            state?.form?.shippingInformation?.zipcode ||
            state?.member?.zip_code,
          state:
            state?.form?.shippingInformation?.state ||
            getMemberState(state?.member?.state) ||
            '',
          phoneNumber:
            state?.form?.shippingInformation?.phoneNumber ||
            normalizePhone(state?.member?.phone_home),
          email:
            state?.form?.shippingInformation?.email || state?.member?.email,
        }}
        disableNameFields
        configValues={state.configValues}
        onSubmit={onSubmit}
        onValidationError={onEmailOrPhoneFieldError}
      />
    </>
  );
};

export default ShippingInformationStep;
