import { H3, Modal as LeavesModal, colors, size, mediaQueries } from '@everlywell/leaves';
import styled from "styled-components";

const Modal = styled(LeavesModal)`
  position: relative;
  top: 0;
  max-height: max-content;
  font-weight: 300;
  font-size: 18px;
  color: ${colors.gray4};
  border: 0;
  box-shadow: 0 2px 20px -5px rgb(145 145 147/40%);

  strong {
    font-weight: 600;
  }

  a {
    color: ${colors.green4};
    text-decoration: none;

    &:hover {
      color: ${colors.green5};
      text-decoration: underline;
    }
  }
`

const ModalContainer = styled.div<{ open: boolean; }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-height: 100%;
  width: 100%;
  padding: ${size.xl1}px 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10;

  > *:first-child {
    pointer-events: none;
  }

  ${props => !props.open && ` width: 0; min-height: 0;`}

  ${mediaQueries.forTabletVerticalDown} {
    padding: 0;
  }
`

const ModalHeading = styled(H3)`
  font-weight: 500;
`

const ModalHeader = styled.header`
  width: 100%;
  margin: 0 auto;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
`

const ModalContent = styled.article`
  width: 100%;
  margin: 0 auto;
  padding-right: 20px;
  padding-left: 20px;
`

export {
  Modal,
  ModalContainer,
  ModalHeading,
  ModalHeader,
  ModalContent
}
