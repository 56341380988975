import { H3, Button, LocalizeDisabled } from '@everlywell/leaves';
import apiOrder from 'api/order-placement';
import { PageInfo } from 'components/eligibility-file/common';
import { useWizard } from 'components/ui';
import { navigate } from 'gatsby';
import React, { useState, useEffect } from 'react';
import {
  analytics,
  displayError,
  trim,
  parseErrorsMessage,
  formatDateOfBirth,
} from 'utils/helpers';
import { getClientData } from './helpers';

import * as S from '../styles';
import { ChildProps } from 'components/ui/Wizard';

interface APIError {
  response?: {
    data?: {
      errors?: string | string[];
    }
  }
}

const ReviewStep = ({ navigation, isCurrentStep }: ChildProps) => {
  const { goToStep } = navigation;
  const { state } = useWizard();
  const [isOrderBeingPlaced, setIsOrderBeingPlaced] = useState(false);

  const {
    configValues,
    member,
    form: { verifyInformation, shippingInformation, selectTests },
    contentfulData,
  } = state;

  const { kind, dob, id, lastName } = verifyInformation;
  const {
    address1,
    address2,
    city,
    state: stateAddress,
    email,
    phoneNumber,
    zipcode,
  } = shippingInformation;

  const isCaregiverKind = kind === 'caregiver';
  const caregiverKindLabel =
    configValues.caregiver_label === ''
      ? 'Health plan caregiver'
      : configValues.caregiver_label;
  const memberKindLabel =
    configValues.member_label === ''
      ? 'Health plan member'
      : configValues.member_label;
  const memberLabel = isCaregiverKind ? caregiverKindLabel : memberKindLabel;

  const firstName = member?.first_name;

  const lineItems = selectTests.map((id: number) => ({
    product_id: id,
    quantity: 1,
  }));

  const eventData = {
    page_name: 'review_order_page',
  };

  const handleError = (error: APIError) => {
    const errors = Array.from(error?.response?.data?.errors || []);
    const [title, content] = parseErrorsMessage(errors);

    return displayError(title, content);
  };

  const handleSubmit = async () => {
    if (isOrderBeingPlaced) return;

    setIsOrderBeingPlaced(true);

    const { programName, enterpriseClientId } = getClientData(selectTests, member.kits);

    const payload = {
      enterprise_partner_id: process.env.GATSBY_ENTERPRISE_PARTNER_ID,
      enterprise_client_id: enterpriseClientId,
      email,
      line_items: lineItems,

      ship_address: {
        firstname: trim(firstName),
        lastname: trim(lastName),
        address1: trim(address1),
        address2: trim(address2),
        city: trim(city),
        state: trim(stateAddress),
        zipcode: trim(zipcode),
        country: 'US',
        phone: phoneNumber,
      },

      user_id: '',
      third_party_member_id: member?.partner_member_id,
      auto_register: true, // all the orders will be auto register for the MVP

      user: {
        dob: formatDateOfBirth(dob),
        gender: member?.gender,
      },

      enterprise_program_name: programName,
      member_preferred_language: member?.preferred_language,
    };

    apiOrder(payload)
      .then((response) => {
        analytics.track('CTA Clicked', {
          ...eventData,
          text: 'Place Order Successfully',
        });

        if (response?.data?.number) {
          navigate('/order-placed', {
            state: {
              name: firstName,
              orderNumber: response.data.number,
              memberId: id,
            },
          });
        }
      })
      .catch((error) => {
        analytics.track('CTA Clicked', {
          ...eventData,
          text: 'Place Order Unsuccessfully',
          error_message: Array.from(error?.response?.data?.errors || [])?.[0],
          response_status: error?.response?.status,
        });

        handleError(error);
        setIsOrderBeingPlaced(false);
      });
  };

  useEffect(() => {
    if (!isCurrentStep) return;

    analytics.track('Page Viewed', {
      ...eventData,
      label: 'Viewed Review Order Page',
    });
    // it should run only when is `isCurrentStep` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentStep]);

  return (
    <>
      <S.FormHeading style={{ textAlign: 'center' }}>
        {isCurrentStep && (
          <PageInfo title="Review Order" pageName="order - review order" />
        )}
        <H3>Review Order</H3>
      </S.FormHeading>

      <S.FormGroup>
        <S.FormGroupTitle>
          <div role="heading">Personal Information</div>
          <S.ChangeButton
            onClick={() => {
              analytics.track('CTA Clicked', {
                ...eventData,
                text: 'Change Personal Information Button',
              });
              goToStep(0);
            }}
          >
            Change
          </S.ChangeButton>
        </S.FormGroupTitle>

        <S.FormGroupValue>
          <div>{memberLabel}</div>
        </S.FormGroupValue>

        <S.FormGroupValue>
          <p>Member ID</p>
          <div>
            <LocalizeDisabled>{id}</LocalizeDisabled>
          </div>
        </S.FormGroupValue>

        <S.FormGroupValue>
          <p>DOB</p>
          <div>
            <LocalizeDisabled>{dob}</LocalizeDisabled>
          </div>
        </S.FormGroupValue>

        <S.FormGroupValue>
          <p>Last Name</p>
          <div>
            <LocalizeDisabled>{lastName}</LocalizeDisabled>
          </div>
        </S.FormGroupValue>

        <S.FormGroupValue>
          <p>Zip Code</p>
          <div>
            <LocalizeDisabled>{zipcode}</LocalizeDisabled>
          </div>
        </S.FormGroupValue>
      </S.FormGroup>

      <S.FormGroup>
        <S.FormGroupTitle>
          <div role="heading">Items in the order</div>
          <S.ChangeButton
            onClick={() => {
              analytics.track('CTA Clicked', {
                ...eventData,
                text: 'Change Items in Order Button',
              });
              goToStep(1);
            }}
          >
            Change
          </S.ChangeButton>
        </S.FormGroupTitle>

        <S.FormGroupValue flexDirection="column">
          {selectTests.map((testId: number) => {
            var product = contentfulData.filter(
              (cD: { productId: number }) => String(cD.productId) === String(testId),
            )?.[0];

            return <div key={testId}>{product?.displayName}</div>;
          })}
        </S.FormGroupValue>
      </S.FormGroup>

      <S.FormGroup>
        <S.FormGroupTitle>
          <div role="heading">Shipping Information</div>
          <S.ChangeButton
            onClick={() => {
              analytics.track('CTA Clicked', {
                ...eventData,
                text: 'Change Shipping Information Button',
              });
              goToStep(2);
            }}
          >
            Change
          </S.ChangeButton>
        </S.FormGroupTitle>

        <S.FormGroupValue>
          <div>
            <LocalizeDisabled>
              {firstName} {lastName}
            </LocalizeDisabled>
          </div>
        </S.FormGroupValue>

        <S.FormGroupValue>
          <div>
            <LocalizeDisabled>
              {address1} {address2}, {city} {stateAddress}, {zipcode}
            </LocalizeDisabled>
          </div>
        </S.FormGroupValue>

        <S.FormGroupValue>
          <p>Phone</p>
          <div>
            <LocalizeDisabled>{phoneNumber}</LocalizeDisabled>
          </div>
        </S.FormGroupValue>

        <S.FormGroupValue>
          <p>Email</p>
          <div>
            <LocalizeDisabled>{email}</LocalizeDisabled>
          </div>
        </S.FormGroupValue>
      </S.FormGroup>

      <S.ButtonGroup>
        <Button
          onClick={handleSubmit}
          isDisabled={isOrderBeingPlaced}
          isLoading={isOrderBeingPlaced}
        >
          Place order
        </Button>
      </S.ButtonGroup>
    </>
  );
};

export default ReviewStep;
