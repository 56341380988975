import { size } from '@everlywell/leaves';
import { HealthPlanCardPlaceholder } from 'components/icons';
import { Divider } from 'components/ui';
import React from 'react';
import { EF_MEMBERS_SUPPORT_FORM } from 'utils/constants';

import * as S from './styles';

interface Props {
  open: boolean;
  openHandler: React.Dispatch<React.SetStateAction<boolean>>;
  uniqueIdLabel: string;
  customContent: string;
  customImage: string;
}

const MemberIDModal = ({
  open,
  openHandler,
  uniqueIdLabel,
  customContent,
  customImage,
  ...rest
}: Props) => {
  return (
    <S.ModalContainer open={open}>
      <S.Modal open={open} openHandler={openHandler} persist={true} {...rest}>
        <S.ModalHeader>
          <S.ModalHeading>
            Where to find your{' '}
            <span data-isolate>{uniqueIdLabel || `member ID`}</span>
          </S.ModalHeading>
          <p>
            Your <span data-isolate>{uniqueIdLabel || `member ID`}</span> is a
            unique code given to you by your health plan. Use this guide to help
            locate it.
          </p>
        </S.ModalHeader>

        <Divider spacing={`${size.md}px`} isHidden />

        <S.ModalContent>
          {customContent ? (
            <section dangerouslySetInnerHTML={{ __html: customContent }} />
          ) : (
            <section>
              <p>
                <strong>
                  Look for a long string of numbers and/or letters
                </strong>
              </p>
              <p>
                Health plans give you a unique member ID and it is typically
                found directly under your name on your card.
              </p>
            </section>
          )}

          <Divider spacing={`${size.xs1}px`} isHidden />

          {customImage ? (
            <img src={customImage} alt="Member ID card" />
          ) : (
            <HealthPlanCardPlaceholder />
          )}

          <Divider spacing={`${size.xs1}px`} isHidden />

          <section>
            <p>
              <strong>
                If you are still having trouble locating your {uniqueIdLabel || `member ID`}
              </strong>
            </p>
            <p>
              Feel free to reach out to our customer service team! Reach us at{' '}
              <a href={`https://${EF_MEMBERS_SUPPORT_FORM}`} target="_blank">
                {EF_MEMBERS_SUPPORT_FORM}
              </a>
              .
            </p>
          </section>
        </S.ModalContent>
      </S.Modal>
    </S.ModalContainer>
  );
};

export default MemberIDModal;
