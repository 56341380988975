import { gql } from '@apollo/client';
import { client } from 'apollo/client';
import { useQuery } from 'react-apollo';

export interface EnterprisePartnerConfig {
  third_party_tenant_name: string;
  health_plan_config: {
    caregiver_label: string;
    logo_url: string | null;
    member_label: string;
    modal_image: string | null;
    modal_text: string;
    unique_id_label: string;
    email_required: string;
    phone_required: string;
  }
}

export interface QueryResult {
  loading: boolean;
  data: {
    enterprise_partner_configs: EnterprisePartnerConfig[] | [];
  } | undefined;
}

const useGetConfigValues = () => {
  const GET_CONFIGS = gql`
    query GetConfigValues($partnerId: bigint) {
      enterprise_partner_configs(
        where: {
          enterprise_partner_id: { _eq: $partnerId }
          enterprise_client_id: { _is_null: true }
        }
      ) {
        third_party_tenant_name
        health_plan_config
      }
    }
  `;

  const { loading, data }: QueryResult = useQuery(GET_CONFIGS, {
    client: client,
    variables: { partnerId: process.env.GATSBY_ENTERPRISE_PARTNER_ID },
  });

  return { loading, data };
};

export default useGetConfigValues;
