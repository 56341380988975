import defaultImage from 'assets/images/120x80.jpeg';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import * as S from '../../styles';

interface Props {
  productId: number;
  contentfulData: {
    imageSrc?: string;
    displayName?: string;
    shortDescription?: string;
  };
}

const KitItemFields = ({ productId, contentfulData }: Props) => {
  const { register, watch } = useFormContext();

  const image = contentfulData?.imageSrc;
  const title = contentfulData?.displayName;
  const subTitle = contentfulData?.shortDescription;

  const imageSrc = image ?? defaultImage;

  const selectedTests = watch('tests');
  const tests = Array.isArray(selectedTests) ? selectedTests : [selectedTests];
  const checked = tests.includes(String(productId));
  const imageAlt = `This is a product image for ${title}`;

  return (
    <S.KitOptionLabel checked={checked}>
      <input
        type="checkbox"
        name="tests"
        value={productId}
        ref={register({ required: true })}
      />

      <S.Label>
        <S.ImageDiv>
          <S.Image src={imageSrc} alt={imageAlt} />
        </S.ImageDiv>
        <S.LabelText>
          <S.LabelTextTitle>{title}</S.LabelTextTitle>
          <S.LabelTextSubTitle>{subTitle}</S.LabelTextSubTitle>
        </S.LabelText>
      </S.Label>
    </S.KitOptionLabel>
  );
};

export default KitItemFields;
